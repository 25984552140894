.layout-half,
.layout-fourth,
.layout-third {
  @apply grid gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-2;
}

.layout-half {
  @apply md:grid-cols-2 lg:grid-cols-2;
}

.layout-fourth {
  @apply md:grid-cols-2 xl:grid-cols-4;
}

.layout-third {
  @apply md:grid-cols-2 lg:grid-cols-3;
}

.layout-giftPage {
  @apply md:grid-cols-1 lg:grid-cols-2;
}

.wrapper {
  & :global(.block-header) {
    @apply pb-4;
  }

  &:global(.has-white-cards) {
    & :global(.card) {
      @apply bg-white p-6;
    }
  }
}
